import React, { useRef, useState } from "react";
import img from "./Assets/Link-BotW.png";
import {
  Box,
  Typography,
  Button,
  Grid,
  Input,
} from "@material-ui/core";
import {DateTimePicker, LocalizationProvider} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Styles from "./CardStyle";
import luca from './Assets/luca.jpg';

const Card = () => {
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [departing, setDeparting] = useState({});
  const [returning, setReturning] = useState({});
  const [url, setUrl] = useState('');

  const handleFrom = (event) => setFrom(event.target.value);
  const handleTo = (event) => setTo(event.target.value);
  const handleDeparting = (event) => setDeparting(event);
  const handeReturning = (event) => setReturning(event);
  const handleUrl = (event) => setUrl(event.target.value);

  const classes = Styles();
  const values = {
    title: 'Happy Birthday!',
    info: `You have been gifted one free European return flight or train ride for the year 2024. Fill out the form below to redeem your flight.`,
    buttonText: 'Redeem Flight',
    effect3D: 'effect3D',
    file: [{
      name: 'mclovin',
      preview: luca,
    }],
    alert: false,
  };
  const container = useRef();
  const card = useRef();
  const sneaker = useRef();
  const description = useRef();
  const title = useRef();
  const purchase = useRef();

  const images = values.file.map((file) => {
    return (
      <img
        key={file.name}
        src={file.preview}
        alt={'mclovin doing some lovin'}
        className={classes.img}
      />
    );
  });

  const cMouseMove = (move) => {
    const xAxis = (window.innerWidth / 2 - move.pageX) / 25;
    const yAxis = (window.innerHeight / 2 - move.pageY) / 25;
    card.current.style.transform = `rotateY(${xAxis}deg) rotateX(${yAxis}deg)`;
  };

  const cMouseEnter = () => {
    card.current.style.transition = "none";
    title.current.style.transform = "translateZ(150px)";
    sneaker.current.style.transform = "translateZ(200px) rotateZ(-3deg)";
    description.current.style.transform = "translateZ(125px)";
    purchase.current.style.transform = "translateZ(75px)";
  };

  const cMouseLeave = () => {
    card.current.style.transition = "all 0.5s ease";
    card.current.style.transform = `rotateY(0deg) rotateX(0deg)`;
    title.current.style.transform = "translateZ(0px)";
    sneaker.current.style.transform = "translateZ(0px) rotateZ(0deg)";
    description.current.style.transform = "translateZ(0px)";
    purchase.current.style.transform = "translateZ(0px)";
  };

  const validate = () => {
    console.log(from,to,departing,returning);
    return Boolean(from !== '' && to  !== '' && departing !== '' && returning !== '');
  };

  const redeemFlight = () => {
    if (!validate()) return;

    const server = 'https://lucabday-5362ca037c89.herokuapp.com/';

    const params = {
      from,
      to,
      departing: departing.toISOString(),
      returning: returning.toISOString(),
      url,
    };

    const queryParams = (params) => {
      return Object.keys(params)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');
    }

    fetch(`${server}?${queryParams(params)}`, {
      method: 'GET',
    })
    .then(response => response.json())
    .then(data => console.log(data))
    .catch((error) => {
      console.error('Error:', error);
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container spacing={3}>
        <Box
          onMouseMove={(move) => values.effect3D && cMouseMove(move)}
          onMouseEnter={() => values.effect3D && cMouseEnter()}
          onMouseLeave={() => values.effect3D && cMouseLeave()}
          ref={container}
          className={classes.container}
        >
          <Box ref={card} className={classes.card}>
            <Box ref={sneaker} className={classes.sneaker}>
              <Box className={classes.circle} />
              {!images.length ? (
                <img className={classes.img} src={img} alt="Link-BotW" />
              ) : (
                images
              )}
            </Box>
            <Box>
              <Typography className={classes.infoH1} variant="h1" ref={title}>
                {values.title}
              </Typography>
              <Typography
                className={classes.infoH3}
                variant="h3"
                ref={description}
              >
                {values.info}
                <br />
                <br />
                <div>
                  <i>From Miguel and Martin</i>
                </div>
              </Typography>
              <Box ref={purchase} className={classes.purchase}>
                <div>
                  <label>From</label>
                  <div>
                    <Input placeholder="Munich" value={from} onChange={handleFrom} />
                  </div>
                </div>
                <br />
                <div>
                  <label>To</label>
                  <div>
                    <Input placeholder="Malta" value={to} onChange={handleTo}/>
                  </div>
                </div>
                <br />
                <div>
                  <label>Departing</label>
                  <DateTimePicker value={departing} onChange={handleDeparting}/>
                </div>
                <br />
                <div>
                  <label>Returning</label>
                  <DateTimePicker value={returning} onChange={handeReturning}/>
                </div>
                <br />
                <div>
                  <label>Url</label>
                  <div>
                    <Input placeholder="https://austrian-airlines.com/bleybley" value={url} onChange={handleUrl}/>
                  </div>
                </div>
                <br />
                <div>
                  <Button
                    className={classes.pButton}
                    variant="contained"
                    color="primary"
                    onClick={redeemFlight}
                  >
                    {values.buttonText}
                  </Button>
                </div>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
    </LocalizationProvider>
  );
};

export default Card;
